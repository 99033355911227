import styled from 'styled-components'
import { Space, Button, Row, List } from 'antd'
import Title from 'antd/lib/typography/Title'
import { ArrowRightOutlined } from '@ant-design/icons'

export const SpaceBgPurple = styled(Space)`
  background-color: #43043b;
  display: flex;
  margin-top: -100px;
  align-items: stretch;
  @media (max-width: 767px) {
    margin-top: 60px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin-top: 60px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 60px;
  }
`
export const TitleList = styled(Title)`
  color: #f16749 !important;
  margin: 0 !important;
  line-height: 38px !important;
  @media (max-width: 767px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 24px !important;
    line-height: 32px !important;

    & .anticon {
      color: #ffffff;
      transition: all 0.5s;
    }
  }
`

export const BtnLinkWhite = styled(Button)`
  color: #ffffff;
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  font-size: 18px;
  height: 24px;
`
export const StyledRow = styled(Row)`
  width: 100%;
  padding-top: 200px;
  padding-bottom: 92px;
  @media (max-width: 767px) {
    padding: 68px 0;
    margin: 0 !important;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 68px 0;
    margin: 0 !important;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 70px 0;
    margin: 0 !important;
  }

  @media (min-width: 1200px) {
    margin: 0 !important;
  }

  & > .ant-col > .ant-list > .ant-list-header {
    padding: 0;
    border: unset;

    @media (max-width: 767px) {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  &
    > .ant-col
    > .sub-categories-list.active
    > .ant-list-header
    .ant-list-item
    .ant-typography
    .anticon {
    transform: rotate(90deg);
  }

  & .sub-categories-list .ant-spin-nested-loading .ant-spin-container {
    @media (max-width: 767px) {
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s;
      border-bottom: 1px solid #e2e2e2;
    }
  }

  & .sub-categories-list.active .ant-spin-nested-loading .ant-spin-container {
    @media (max-width: 767px) {
      max-height: 400px;
    }
  }
`
export const StyledArrowRightOutlined = styled(ArrowRightOutlined)`
  display: none;

  @media (max-width: 767px) {
    display: block;
    font-size: 20px;
    margin: auto 0;
  }
`

export const StyledListItem = styled(List.Item)`
  border: unset !important;
  @media (max-width: 767px) {
    padding: 8px 0;
  }
`

export const StyledListItemTitle = styled(List.Item)`
  border: unset !important;
  padding-top: 0;
  @media (max-width: 767px) {
    padding: 0;
  }
`
export const StyledSpaceBtn = styled(Space)`
  padding-bottom: 212px;
  width: 100%;
  justify-content: center;
  @media (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 80px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding-bottom: 80px;
  }
`
