import styled from 'styled-components'

export const AfterHoursBanner = styled.div`
  max-width: 1400px;
  width: 100%;
  //height: 149px;
  padding: 20px 40px;
  margin: 20px 15px 0;
  background-size: cover;
  .moon-after-hours {
    max-width: 197px;
    max-height: 154px;
    width: 100%;
    height: 100%;
    margin-right: 11px;
    margin-left: auto;
    margin-bottom: -20px;
  }
  .button-after-hours {
    font-size: 16px;
  }
  .banner-text-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .banner-title {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    margin: 0;
    /* Neutral / White */

    color: #ffffff;
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .banner-subtitle {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    /* identical to box height, or 133% */

    text-align: left;

    /* Neutral / White */

    color: #ffffff;
  }
  @media (max-width: 850px) {
    .moon-after-hours {
      max-width: 80px;
      max-height: 80px;
      margin-left: 11px;
      margin-right: 0px;
      margin-bottom: -75px;
    }
    .button-after-hours {
      margin-left: 20px;
    }
    padding: 40px;
  }
  @media (max-width: 791px) {
    .banner-title {
      font-size: 30px;
    }
    .banner-subtitle {
      font-size: 16px;
    }
    .button-after-hours {
      font-size: 14px;
    }
  }
  @media (max-width: 760px) {
    .moon-after-hours {
      order: 3;
      margin-bottom: -98px;
    }
  }
  @media (max-width: 650px) {
    padding: 20px 16px;
    margin: 20px 0;
    position: relative;
    .banner-text-wrapper {
      flex-direction: column;
      text-align: center;
      justify-content: center;
    }
    .text-wrapper {
      width: 100%;
    }
    .banner-title {
      font-size: 20px;
      line-height: 26px;
    }
    .banner-subtitle {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 15px;
      text-align: center;
    }
    .button-after-hours {
      font-size: 14px;
      margin-top: 10px;
      margin-left: 0;
    }
    .moon-after-hours {
      margin-bottom: 0;
      position: absolute;
      right: 10px;
      bottom: 0;
    }
  }
  @media (max-width: 425px) {
    .moon-after-hours {
      max-width: 60px;
      max-height: 60px;
    }
  }
`
