import { Modal } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 95px;
  margin-top: 195px;
  .carousel {
    margin-top: 80px;
    max-width: 85vw;
    margin: auto;
  }
  .review-text {
    margin: 0;
    margin-top: 30px;
    font-family: Sora;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;
    /* or 130% */

    text-align: center;

    /* Orange / 40 - P */

    color: #f16749;
  }
  @media (min-width: 1700px) {
    .carousel {
      max-width: 75vw;
    }
  }
  @media (min-width: 2000px) {
    .carousel {
      max-width: 62vw;
    }
  }
  .ant-carousel .slick-dots {
    position: static;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .ant-carousel .slick-dots .slick-active {
    width: 3px;
    height: 3px;
    background: #f16749;
    opacity: 1;
  }

  .ant-carousel .slick-dots li {
    width: 3px;
    height: 3px;
    background: transparent;
    border: 2px solid #f16749;
    border-radius: 50%;
  }

  .ant-carousel .slick-dots li button {
    background: transparent !important;
  }

  .title {
    margin: 0;
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;

    /* Purple / 70 - P */

    color: #7b2e6f;
  }
  .description {
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */
    max-width: 800px;
    text-align: center;
    margin: auto;
    margin-bottom: 80px;
    margin-top: 30px;

    /* Orange / 40 - P */

    color: #f16749;
  }
  .ant-modal-content {
    box-shadow: none !important;
    background-color: transparent !important;
  }

  @media (max-width: 1200px) {
    margin: 50px;
    margin-top: 195px;
  }
  @media (max-width: 900px) {
    margin: 40px;
    margin-top: 150px;
  }
  @media (max-width: 600px) {
    margin: 25px;
    margin-top: 130px;
  }
  @media (max-width: 600px) {
    .description {
      margin-bottom: 0;
      margin-top: 30px;
      font-size: 18px;
    }
    .carousel {
      margin-top: 45px;
    }
    & button.slick-arrow.slick-prev {
      left: -5px;
      z-index: 9;
    }
    & button.slick-arrow.slick-next {
      right: -10px;
      z-index: 9;
    }
  }
  @media (max-width: 400px) {
    .description {
      font-size: 16px;
    }
    .title {
      font-size: 30px;
    }
  }
`
export const ReviewCard = styled.div`
  max-width: 447px;
  height: 500px;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 40px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  .wrapper {
    height: 72%;
  }
  .see-more {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  .review-description {
    margin: 0;
    margin-top: 20px;
    font-family: Sora;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    /* or 130% */

    text-align: center;

    /* Orange / 40 - P */

    color: #f16749;
  }
  .text {
    margin: 0;
    margin-top: 16px;
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 26px;
    /* identical to box height, or 186% */

    text-align: center;

    /* Purple / 80 */

    color: #55204d;
  }
  .stars {
    font-size: 16px;
  }
  .avatar {
    width: 45px;
    height: 45px;
    border-radius: 100px;
    margin: auto;
    margin-top: 36px;
  }
  @media (max-width: 1500px) {
    padding: 30px;
    padding-bottom: 30px;
  }
  @media (max-width: 1450px) {
    padding: 30px;
    padding-bottom: 30px;
    height: 550px;
  }
  @media (max-width: 1400px) {
    .review-description {
      font-size: 16px;
    }
  }
  @media (max-width: 1300px) {
    .review-description {
      font-size: 16px;
    }
    .text {
      font-size: 16px;
    }
  }
  @media (max-width: 1250px) {

    .review-description {
      font-size: 14px;
    }
    .text {
      font-size: 14px;
    }
    height: 500px;
  }
  @media (max-width: 1150px) {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 20px;
    .review-description {
      font-size: 14px;
      margin-top: 10px;
    }
    .avatar {
      width: 30px;
      height: 30px;
    }
    .text {
      margin-top: 0;
    }
    .wrapper {
      height: 75%;
    }
    height: 500px;
  }
  @media (max-width: 1000px) {
    .review-description {
      font-size: 14px;
    }
    .text {
      font-size: 14px;
    }
    .wrapper {
      height: 77%;
    }
    height: 520px;
  }

  @media (max-width: 900px) {
    padding: 30px;
    padding-top: 40px;
    .review-description {
      font-size: 16px;
      margin-top: 15px;
    }
    height: 500px;
  }
  @media (max-width: 800px) {
    .review-description {
      font-size: 14px;
    }
    .text {
      font-size: 14px;
    }
    .wrapper {
      height: 80%;
    }
  }
  @media (max-width: 700px) {
    /* .wrapper {
      height: 65%;
    } */
    padding: 20px;
    padding-top: 30px;
    .review-description {
      font-size: 14px;
      margin-top: 12px;
    }
    .text {
      font-size: 12px;
    }
    .wrapper {
      height: 78%;
    }
    height: 500px;
  }
  @media (max-width: 650px) {
    padding: 20px;
    padding-top: 20px;
  }
  @media (max-width: 600px) {
    padding: 30px;
    padding-top: 40px;
    width: 400px !important;
    .review-description {
      font-size: 16px;
      margin-top: 20px;
    }
    .text {
      font-size: 14px;
    }
    height: 450px;
    .wrapper {
      height: 75%;
    }
  }
  @media (max-width: 530px) {
    width: 350px !important;
    .review-description {
      font-size: 16px;
      line-height: 24px;
    }
    .text {
      font-size: 14px;
    }
  }
  @media (max-width: 470px) {
    width: 300px !important;
    .review-description {
      margin-top: 12px;
      font-size: 14px;
      line-height: 24px;
    }
    .text {
      font-size: 14px;
    }
    height: 450px;
    .wrapper {
      height: 78%;
    }
    .stars {
      font-size: 14px;
    }
  }
  @media (max-width: 400px) {
    padding: 20px;
    padding-top: 30px;
    width: 256px !important;
    height: 475px;
    .wrapper {
      height: 75%;
    }
  }
  @media (max-width: 350px) {
    width: 200px !important;
    height: 500px;
    padding: 20px;
    padding-top: 20px;
    .review-description {
      font-size: 12px;
    }
    .text {
      font-size: 12px;
    }
    .wrapper {
      height: 77%;
    }
  }
`
export const StyledModal = styled(Modal)`
  .ant-modal-content {
    position: relative;
    background-color: transparent;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    box-shadow: none;
    pointer-events: auto;
  }
  .ant-modal-close {
    position: absolute;
    top: -20px;
    right: 0;
    z-index: 10;
    padding: 0;
    color: white;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
  }
`
