import styled from 'styled-components'
import { Space, Row } from 'antd'
import Title from 'antd/lib/typography/Title'
import { FacebookFilled, InstagramOutlined } from '@ant-design/icons'

export const StyledSpaceContent = styled(Space)`
  display: flex;
  align-items: stretch;
  margin-top: 200px;
  @media (max-width: 767px) {
    margin-top: 100px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 100px;
  }

  @media (min-width: 1200px) and (max-width: 1450px) {
    margin-top: 100px;
  }

  & .ant-space-item:nth-child(3) {
    position: relative;
  }
`
export const StyledSpaceIcons = styled(Space)`
  margin-bottom: 48px;
  width: 100%;
  justify-content: center;
`

export const StyledTitle = styled(Title)`
  color: #7b2e6f !important;
  text-align: center;
`

export const StyledFacebookIcon = styled(FacebookFilled)`
  color: #f16749;
  font-size: 28px;
`

export const StyledInstagramIcon = styled(InstagramOutlined)`
  color: #f16749;
  font-size: 28px;
`
export const StyledRow = styled(Row)`
  margin: 0 95px !important;
  @media (min-width: 1921px) {
    max-width: 1726px;
    margin: 0 auto !important;
  }

  & :after {
    content: '';
    background: #43043b;
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    z-index: 1;
  }

  & .ant-col {
    z-index: 9;
  }

  @media (max-width: 767px) {
    margin: 0 4px !important;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 30px !important;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 30px !important;
  }

  @media (min-width: 1200px) and (max-width: 1450px) {
    margin: 0 30px !important;
  }

  & .ant-col-xs-12 .ant-image {
    @media (max-width: 767px) {
      height: auto !important;
      width: auto !important;
    }
  }

  & .ant-col-xs-12 .ant-image img {
    @media (max-width: 767px) {
      height: auto !important;
    }
  }

  & .ant-col-md-12 .ant-image {
    @media (min-width: 768px) and (max-width: 991px) {
      height: auto !important;
      width: auto !important;
    }
  }

  & .ant-col-md-12 .ant-image img {
    @media (min-width: 768px) and (max-width: 991px) {
      height: auto !important;
    }
  }

  & .ant-col-lg-6 .ant-image {
    @media (min-width: 992px) and (max-width: 1199px) {
      height: auto !important;
      width: auto !important;
    }

    @media (min-width: 1200px) {
      height: auto !important;
      width: auto !important;
    }
  }

  & .ant-col-lg-6 .ant-image img {
    @media (min-width: 992px) and (max-width: 1199px) {
      height: auto !important;
    }

    @media (min-width: 1200px) {
      height: auto !important;
    }
  }
`
