import styled from 'styled-components'

export const NewsLetterContent = styled.div`
  padding-top: 87px;
  background: #43043b;

  @media (max-width: 767px) {
    padding-top: 40px;
  }

  & .newsletter-content {
    max-width: 685px;
    margin: 0 auto;
  }

  & .newsletter-content h2.ant-typography {
    text-align: center;
    color: #f16749;
    /* max-width: 445px; */
    margin: auto;
    margin-bottom: 32px;

    @media (max-width: 767px) {
      //styleName: Text / Heading / H3 30px Semibold;
      font-family: Sora;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
      letter-spacing: 0px;
      text-align: center;

      max-width: unset;
      margin-bottom: 16px;
    }
  }

  & .newsletter-content .ant-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;

    @media (max-width: 767px) {
      display: block;
    }
  }

  & .newsletter-content .ant-form .ant-row.ant-form-item:last-child {
    grid-column: 1 / 2 span;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
  }

  & .newsletter-content .ant-form .ant-row.ant-form-item {
    margin-bottom: 32px;
  }

  & .newsletter-content .ant-form .ant-row.ant-form-item:nth-child(1) {
    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
  }

  & .newsletter-content span.ant-typography {
    display: block;
    text-align: center;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }

  & .ant-divider {
    margin-bottom: 0;
    margin-top: 60px;
    border-color: rgba(255, 255, 255, 0.2);
    @media (max-width: 767px) {
      margin-top: 40px;
    }
  }

  & .newsletter-content .ant-form .ant-row.ant-form-item label {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
  }

  & .newsletter-content .ant-form .ant-row.ant-form-item input {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    padding: 6.5px 12px;
    font-size: 16px;
  }

  & .newsletter-content .ant-form .ant-row.ant-form-item .ant-btn {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .terms-and-conditions-wrapper a {
    @media (max-width: 767px) {
      display: block;
      margin-top: 6px;
    }
  }
`
