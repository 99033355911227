import Container from '@/components/shared/Container'
import { CategoryProps } from '@/Props/CategoryProps'
import { ProductSectionProps, StrapiCategoryProps } from '@/Props/HomePage/ProductSectionProps'
import adminApi from '@/services/adminApi'
import api from '@/services/api'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Button, Col, List } from 'antd'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import {
  SpaceBgPurple,
  TitleList,
  BtnLinkWhite,
  StyledRow,
  StyledArrowRightOutlined,
  StyledListItemTitle,
  StyledListItem,
  StyledSpaceBtn,
} from './styles'
import * as Sentry from '@sentry/nextjs'
interface ProductSectionItemProps {
  category: CategoryProps
}

const ProductSectionItem = ({ category }: ProductSectionItemProps) => {
  const [listItems, setListItems] = useState(false)
  const ShowListItems = () => {
    if (window.innerWidth < 767 && !listItems) {
      setListItems(true)
    } else {
      setListItems(false)
    }
  }

  const router = useRouter()

  return (
    <Col xs={24} sm={24} md={12} lg={6} xl={6} key={category.id}>
      <List
        className={listItems ? 'sub-categories-list active' : 'sub-categories-list'}
        header={
          <StyledListItemTitle onClick={() => ShowListItems()}>
            <TitleList level={3}>
              {category.name}
              <StyledArrowRightOutlined />
            </TitleList>
          </StyledListItemTitle>
        }
      >
        {category.childCategories?.slice(0, 5).map((childCategory) => {
          return (
            <StyledListItem key={childCategory.id}>
              <BtnLinkWhite
                type="link"
                size="small"
                onClick={() => router.push(`/categoria/${childCategory.slug}`)}
              >
                {childCategory.name}
                <StyledArrowRightOutlined />
              </BtnLinkWhite>
            </StyledListItem>
          )
        })}
      </List>
    </Col>
  )
}

const ProductSection = ({ categorias, button }: ProductSectionProps) => {
  const [apiCategories, setApiCategories] = useState<CategoryProps[]>([])

  const getCategoriesOrders = async () => {
    const localApiCategories: CategoryProps[] = []
    for await (const category of categorias) {
      try {
        const { data: apiCategory } = await api.get<CategoryProps>(
          `categories/${category.slug}?isPrincipal=true`
        )
        localApiCategories.push(apiCategory)
      } catch (error) {
        Sentry.captureException(error)
      }
    }
    localApiCategories.sort((a, b) => {
      const keyA = a.order,
        keyB = b.order
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })
    setApiCategories(localApiCategories)
  }

  useEffect(() => {
    getCategoriesOrders()
  }, [])

  const router = useRouter()

  return (
    <SpaceBgPurple direction="vertical" align="center" size={0}>
      <Container>
        <StyledRow
          gutter={[
            { md: 50, lg: 50, xl: 100 },
            { xs: 20, md: 30, lg: 0 },
          ]}
        >
          {apiCategories.map((category, index) => {
            return <ProductSectionItem key={index} category={category} />
          })}
        </StyledRow>
        <StyledSpaceBtn align="center">
          <Button type="primary" size="large" onClick={() => router.push(`${button.href}`)}>
            {button.label}
          </Button>
        </StyledSpaceBtn>
      </Container>
    </SpaceBgPurple>
  )
}

export default ProductSection
