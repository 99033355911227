import { DescriptionSectionProps } from '@/Props/HomePage/DescriptionSectionProps'
import { StrapiImageProps } from '@/Props/StrapiImageProps'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { Button, Space } from 'antd'
import { isAfter, isBefore } from 'date-fns'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { SponsoredTag } from '../HomePagePrincipalBanner/styles'
import { StyledSpaceContent, StyledTitle } from './styles'
import VisibilitySensor from 'react-visibility-sensor'
import sendSponsorInfoToGTM from '@/utils/sendSponsorInfoToGTM'

interface AdsProps {
  image_ads: StrapiImageProps
  title_ads: string
  subtitle_ads: string
  url?: string
  begin_date_ads?: string
  end_date_ads?: string
}
const DescriptionSection = ({
  content: { title, subtitle },
  image,
  multiple_content_ads,
}: DescriptionSectionProps) => {
  const [adToShow, setAdToShow] = useState<AdsProps>()
  const sendDataToGTM = useGTMDispatch()
  const router = useRouter()
  const [adsSentToGTM, setAdsSentToGTM] = useState<string[]>([])

  useEffect(() => {
    if (multiple_content_ads) {
      let adsFound = false
      multiple_content_ads.map((eachAd) => {
        if (eachAd.image_ads && eachAd.title_ads && eachAd.subtitle_ads && !adsFound) {
          if (eachAd.begin_date_ads && eachAd.end_date_ads) {
            if (
              isAfter(new Date(), new Date(eachAd.begin_date_ads)) &&
              isBefore(new Date(), new Date(eachAd.end_date_ads))
            ) {
              adsFound = true
              setAdToShow(eachAd)
            }
          }
        }
      })
    }
  }, [multiple_content_ads])

  const promotionClick = () => {
    if (adToShow) {
      const adsName = adToShow.title_ads.toLocaleUpperCase().split('')
      adsName.map((char, index) => {
        const checkIfHasWhiteSpace = (s) => {
          return /\s/g.test(s)
        }
        const hasWhiteSpace = checkIfHasWhiteSpace(char)
        if (hasWhiteSpace) {
          adsName[index] = '-'
        }
      })
      sendDataToGTM({ ecommerce: null })
      sendDataToGTM({
        event: 'promotionClick',
        ecommerce: {
          promoView: {
            promotions: [
              {
                id: adsName.join(''),
                name: adToShow.title_ads,
                creative: `home-page-mid-hero-${adsName.join('').toLocaleLowerCase()}`,
                position: `home-page-mid-hero-${adsName.join('').toLocaleLowerCase()}`,
              },
            ],
          },
        },
      })
      router.push(adToShow.url)
    }
  }
  return (
    <VisibilitySensor
      onChange={(isVisible) =>
        sendSponsorInfoToGTM(
          isVisible,
          adToShow,
          `home-page-mid-hero-`,
          adsSentToGTM,
          sendDataToGTM
        )
      }
    >
      <StyledSpaceContent direction="horizontal" size={149}>
        <div style={{ position: 'relative' }}>
          <Image
            width={843}
            height={634}
            src={`${adToShow ? adToShow.image_ads.url : image.url}`}
          ></Image>
          {adToShow && <SponsoredTag style={{ bottom: 15, right: 10 }}>Ad</SponsoredTag>}
        </div>
        <Space direction="vertical" size={32}>
          <StyledTitle level={2}>{adToShow ? adToShow.title_ads : title}</StyledTitle>
          {adToShow && adToShow.url && (
            <Button
              style={{ display: 'flex', margin: 'auto', alignItems: 'center' }}
              type="primary"
              onClick={() => {
                promotionClick()
              }}
            >
              {adToShow.subtitle_ads}
            </Button>
          )}
        </Space>
      </StyledSpaceContent>
    </VisibilitySensor>
  )
}

export default DescriptionSection
