//import api from '@/services/api'
import PageLayout from '@/components/PageLayout'
import HomePagePrincipalBanner from '@/components/HomePage/HomePagePrincipalBanner'
import HowItWorks from '@/components/HomePage/HowItWorks'
import DescriptionSection from '@/components/HomePage/DescriptionSection'
import ProductSection from '@/components/HomePage/ProductSection'
import DownloadAppSection from '@/components/HomePage/DownloadAppSection'
import FollowUs from '@/components/HomePage/FollowUs'
import AddressInfoTopBar from '@/components/shared/AddressInfoTopBar'
import Link from 'next/link'
import api from '@/services/api'
import { useClient } from '@/contexts/ClientContext'
//import Image from 'next/image'
import { CategoryProps, CategoryPropsPrincipal } from '@/Props/CategoryProps'
import { Carousel, Row, Col } from 'antd'
import NewsletterSection from '@/components/HomePage/NewsletterSection'
import React, { useEffect, useState } from 'react'
import { HomePagePrincipalBannerProps } from '@/Props/HomePage/HomePagePrincipalBannerProps'
import { DescriptionSectionProps } from '@/Props/HomePage/DescriptionSectionProps'
import { HowItWorksProps } from '@/Props/HomePage/HowItWorksProps'
import {
  AdsProps,
  ProductSectionProps,
  StrapiCategoryProps,
} from '@/Props/HomePage/ProductSectionProps'
import { DownloadAppSectionProps } from '@/Props/HomePage/DownloadAppSectionProps'
import { FollowUsProps } from '@/Props/HomePage/FollowUsProps'
import { NewsletterSectionProps } from '@/Props/HomePage/NewsletterSectionProps'
import cmsApi from '@/services/cmsApi'
import { SEOProps } from '@/Props/SEOProps'
import { RightOutlined } from '@ant-design/icons'
import { AutoDestroyModal } from '@/components/shared/AutoDestroyModal'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/client'
import { CustomerAddressProps } from '@/Props/CustomerAddressProps'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/nextjs'
import { ConfigurableProductProps } from '@/Props/ConfigurableProductProps'
import HomeProductLayer from '@/components/HomeProductLayer'
import { ReviewsSectionProps } from '@/Props/HomePage/ReviewsSectionProps'
import ReviewsSection from '@/components/HomePage/ReviewsSection'
import { useWindowDimensions } from '@/utils/useWindowDimensions'
import { isAfter, isBefore } from 'date-fns'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { SponsoredTag } from '@/components/HomePage/HomePagePrincipalBanner/styles'
import VisibilitySensor from 'react-visibility-sensor'
import sendSponsorInfoToGTM from '@/utils/sendSponsorInfoToGTM'
import { SignInCreateAccount } from '@/components/SignInCreateAccount'
import googleMapsAutocompleteParser from '@/utils/googleMapsAutocompleteParser'
import AfterHoursBanner from '@/components/AfterHoursBanner'
import HomePageLoading from '@/components/HomePageLoading'
import { CategoryProducts } from '@/components/CategoryTypesense'
import { GetStaticProps } from 'next'
import LoadingCenter from '@/components/LoadingCenter'
import { useCategory } from '@/contexts/CategoryContext'

interface HomePageCmsProps {
  layout:
    | HomePagePrincipalBannerProps[]
    | DescriptionSectionProps[]
    | HowItWorksProps[]
    | ProductSectionProps[]
    | DownloadAppSectionProps[]
    | ReviewsSectionProps[]
    | FollowUsProps[]
    | NewsletterSectionProps[]
  SEO: SEOProps
}

const settings = {
  arrows: true,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  swipeToSlide: true,
  swipe: true,
  draggable: true,
  vertical: false,
  responsive: [
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
      },
    },
  ],
}

interface renderHomePageComponentsProps {
  nightCategory?: ServerSideProps['categoryInfo'][0]['category']
  component:
    | HomePagePrincipalBannerProps
    | DescriptionSectionProps
    | HowItWorksProps
    | ProductSectionProps
    | DownloadAppSectionProps
    | ReviewsSectionProps
    | FollowUsProps
    | NewsletterSectionProps
}

interface CmsProps {
  id: number
  category: StrapiCategoryProps
}
export interface ServerSideProps {
  cmsCategories: CmsProps[]
  categoryInfo: {
    category: {
      name: string
      slug: string
      banner: string
      is_promotion_category: boolean
      is_sub_category: boolean
      is_night_category: boolean
      cms_id: number
      category_id: number
      is_visible: boolean
      parent_id: string
      products: CategoryProducts[]
    }
  }[]
}

const RenderHomePageComponents = ({ component, nightCategory }: renderHomePageComponentsProps) => {
  switch (component.__component) {
    case 'slices.hero-home':
      return <HomePagePrincipalBanner {...component} />
    case 'slices.mid-hero':
      return <DescriptionSection {...component} />
    case 'slices.how-it-works':
      return <HowItWorks nightCategory={nightCategory} {...component} />
    case 'slices.categories':
      return <ProductSection {...component} />
    case 'slices.app-hero':
      return <DownloadAppSection {...component} />
    case 'slices.reviews':
      return <ReviewsSection {...component} />
    case 'slices.follow-us':
      return <FollowUs {...component} />
    case 'slices.newsletter':
      return <NewsletterSection {...component} />
    default:
      return null
  }
}
interface Props {
  homePageInfo: HomePageCmsProps
  categories: ServerSideProps['categoryInfo'][0]['category'][]
  cmsCategories: CmsProps[]
}
export default function Home(props: Props) {
  const router = useRouter()
  const [session, loading] = useSession()
  const {
    address,
    changeAddressName,
    changeClientAddressId,
    changeClientAddress,
    changeClientCoordinates,
    changeClientCity,
    changeClientDistrict,
    changeClientCountry,
    changeClientPostalCode,
    changeIsLoading,
    isVisibleAfterHours,
  } = useClient()
  const { changeCategoriesMenu } = useCategory()
  const { width } = useWindowDimensions()
  const sendDataToGTM = useGTMDispatch()
  const [adsSentToGTM, setAdsSentToGTM] = useState<string[]>([])
  const [wishProducts, setWishProducts] = useState<ConfigurableProductProps[]>([])
  const [showLoginProductPage, setShowLoginProductPage] = useState(false)
  const [nightCategory, setNightCategory] = useState<
    ServerSideProps['categoryInfo'][0]['category']
  >()
  const [categories, setCategories] = useState<ServerSideProps['categoryInfo'][0]['category'][]>(
    props.categories.length > 0 ? props.categories : []
  )
  const [cmsCategories, setCmsCategories] = useState<CmsProps[]>(
    props.cmsCategories ? props.cmsCategories : []
  )
  const [homePage, setHomePage] = useState<HomePageCmsProps>(
    props.homePageInfo ? props.homePageInfo : ({} as HomePageCmsProps)
  )
  const [SEO, setSEO] = useState<SEOProps>(
    props.homePageInfo && props.homePageInfo.SEO ? props.homePageInfo.SEO : ({} as SEOProps)
  )
  const [loadingInfo, setLoadingInfo] = useState(true)
  const [loadingHome, setLoadingHome] = useState(false)
  useEffect(() => {
    if (props) {
      setLoadingHome(false)
      setLoadingInfo(false)
    }
  }, [props])
  useEffect(() => {
    const getaddressInfo = async () => {
      if (session && !loading) {
        try {
          const prop = await api.get<CustomerAddressProps[]>(
            `/customers/${session.customer.id}/addresses`
          )
          const defaultAddress = prop.data.find((each) => each.is_default)
          if (address) {
            prop.data.map((eachAddress) => {
              if (eachAddress.address == address) {
                changeIsLoading(true)
                Cookies.set('clientAddress', eachAddress.address, { expires: 60 })
                changeAddressName(eachAddress.address_name)
                changeClientAddressId(eachAddress.address_id)
                changeClientAddress(eachAddress.address)
                changeClientCity(eachAddress.city)
                changeClientDistrict(eachAddress.district)
                changeClientCountry(eachAddress.country)
                changeClientPostalCode(eachAddress.postal_code)
                changeClientCoordinates({
                  latitude: eachAddress.latitude,
                  longitude: eachAddress.longitude,
                })
                changeIsLoading(false)
              }
            })
          } else if (!address && defaultAddress) {
            changeIsLoading(true)
            Cookies.set('clientAddress', defaultAddress.address, { expires: 60 })
            changeAddressName(defaultAddress.address_name)
            changeClientAddressId(defaultAddress.address_id)
            changeClientAddress(defaultAddress.address)
            changeClientCity(defaultAddress.city)
            changeClientDistrict(defaultAddress.district)
            changeClientCountry(defaultAddress.country)
            changeClientPostalCode(defaultAddress.postal_code)
            changeClientCoordinates({
              latitude: defaultAddress.latitude,
              longitude: defaultAddress.longitude,
            })
            changeIsLoading(false)
          } else if (!address && prop.data[0]) {
            changeIsLoading(true)
            Cookies.set('clientAddress', prop.data[0].address, { expires: 60 })
            changeAddressName(prop.data[0].address_name)
            changeClientAddressId(prop.data[0].address_id)
            changeClientAddress(prop.data[0].address)
            changeClientCity(prop.data[0].city)
            changeClientDistrict(prop.data[0].district)
            changeClientCountry(prop.data[0].country)
            changeClientPostalCode(prop.data[0].postal_code)
            changeClientCoordinates({
              latitude: prop.data[0].latitude,
              longitude: prop.data[0].longitude,
            })
            changeIsLoading(false)
          } else {
            const addressId = Cookies.get('clientAddressId')
            if (addressId) {
              const response = await googleMapsAutocompleteParser({ placeId: addressId })

              changeIsLoading(true)
              changeClientAddressId(addressId)
              if (response.city && response.street) {
                changeClientAddress(`${response.street}, ${response.city}`)
              } else if (response.street) {
                changeClientAddress(response.street)
              } else if (response.city) {
                changeClientAddress(response.city)
              }
              changeClientCity(response.city)
              changeClientDistrict(response.district)
              changeClientCountry(response.country)
              changeClientPostalCode(response.postal_code)
              changeClientCoordinates({
                latitude: response.lat.toString(),
                longitude: response.lng.toString(),
              })
              changeIsLoading(false)
            }
          }
        } catch (error) {
          Sentry.captureException(error)
        }
      }
    }
    getaddressInfo()
  }, [session, loading])

  useEffect(() => {
    document.querySelector('body').classList.add('home-page')
    return () => {
      document.querySelector('body').classList.remove('home-page')
    }
  }, [])

  useEffect(() => {
    if (router.isReady) {
      const { key } = router.query

      if (key && key != '') {
        try {
          api
            .put('/newsletter-confirmation', { key })
            .then(() => {
              AutoDestroyModal({
                type: 'success',
                title: 'Subscrição da newsletter confirmada',
              })
              window.fbq('track', 'Subscribe', {
                content_name: 'website',
              })
              router.replace('/')
            })
            .catch(() => {
              AutoDestroyModal({
                type: 'error',
                title: 'Pedido de subscrição da newsletter não existe',
              })
              router.replace('/')
            })
        } catch (error) {
          Sentry.captureException(error)
        }
      }
    }
  }, [router])

  const checkCmsInfo = (category: CmsProps) => {
    if (category.category.multiple_content_categories) {
      let adsFound = false
      let adtoshow: AdsProps
      category.category.multiple_content_categories.map((eachAd) => {
        if (eachAd.name_ads && !adsFound) {
          if (eachAd.begin_date_ads && eachAd.end_date_ads) {
            if (
              isAfter(new Date(), new Date(eachAd.begin_date_ads)) &&
              isBefore(new Date(), new Date(eachAd.end_date_ads))
            ) {
              adsFound = true
              adtoshow = eachAd
            }
          }
        }
      })
      if (adtoshow) {
        return adtoshow
      }
    }
    return null
  }

  const sponsorClick = (adToShow: AdsProps, type) => {
    if (adToShow) {
      const adsName = adToShow.name_ads.toLocaleUpperCase().split('')
      adsName.map((char, index) => {
        const checkIfHasWhiteSpace = (s) => {
          return /\s/g.test(s)
        }
        const hasWhiteSpace = checkIfHasWhiteSpace(char)
        if (hasWhiteSpace) {
          adsName[index] = '-'
        }
      })
      sendDataToGTM({ ecommerce: null })
      sendDataToGTM({
        event: 'promotionClick',
        ecommerce: {
          promoView: {
            promotions: [
              {
                id: adsName.join(''),
                name: adToShow.name_ads,
                creative: `${type}${adsName.join('').toLocaleLowerCase()}`,
                position: `${type}${adsName.join('').toLocaleLowerCase()}`,
              },
            ],
          },
        },
      })
    }
  }

  const getWishlist = async () => {
    try {
      const { data: wishListProducts } = await api.get<ConfigurableProductProps[]>(
        `customers/${session.customer.id}/wishlist-info`
      )
      // console.log('data', wishListProducts)
      if (wishListProducts) {
        setWishProducts(wishListProducts)
      }
    } catch (error) {
      const { data: wishListProducts } = await api.get<ConfigurableProductProps[]>(
        `customers/${session.customer.id}/wishlist-info`
      )
      // console.log('data', wishListProducts)
      if (wishListProducts) {
        setWishProducts(wishListProducts)
      }
    }
  }

  useEffect(() => {
    if (categories) {
      const findAfterHours = categories.find((each) => each.is_night_category && each.is_visible)
      if (findAfterHours) {
        setNightCategory(findAfterHours)
      }
    }
  }, [categories])

  useEffect(() => {
    if (session && session.customer && !loading) {
      getWishlist()
    }
  }, [session, loading])

  if (showLoginProductPage && (!session || !session.customer)) {
    return (
      <PageLayout
        title={SEO?.title}
        description={SEO?.description}
        shareImage={SEO?.share_image?.url}
        slug=""
        //menuCategories={menuCategories}
      >
        <SignInCreateAccount />
      </PageLayout>
    )
  }

  if (loadingHome && (!address || address == '')) {
    return (
      <PageLayout>
        <LoadingCenter />
      </PageLayout>
    )
  }
  if (loadingInfo && address) {
    return (
      <PageLayout>
        <AddressInfoTopBar />
        <div className="content-container home-page-container">
          <div className="hp-top">
            <h1>O que vais pedir?</h1>
            <HomePageLoading isVisibleAfterHours={isVisibleAfterHours} />
          </div>
        </div>
      </PageLayout>
    )
  }

  return (
    <PageLayout
      title={SEO?.title}
      description={SEO?.description}
      shareImage={SEO?.share_image?.url}
      slug=""
      // menuCategories={menuCategories}
    >
      {address == '' || !address ? (
        <>
          {homePage.layout.map((component, index) => {
            return (
              <RenderHomePageComponents
                key={index}
                component={component}
                nightCategory={nightCategory}
              />
            )
          })}
        </>
      ) : (
        <>
          <AddressInfoTopBar />
          <div className="content-container home-page-container">
            <div className="hp-top">
              <h1>O que vais pedir?</h1>
              <Row
                className="hp-cat-badges"
                style={{ marginLeft: 0, marginRight: 0 }}
                gutter={{ xs: 20, sm: 20, md: 20, lg: 30, xl: 30 }}
              >
                {categories
                  ?.sort((a, b) =>
                    a.is_night_category == b.is_night_category ? 0 : a.is_night_category ? -1 : 1
                  )
                  .slice(0, 5)
                  .sort((a, b) =>
                    a.is_night_category == b.is_night_category ? 0 : a.is_night_category ? 1 : -1
                  )
                  .slice(0, isVisibleAfterHours ? 5 : 4)
                  .map((category) => {
                    if (category.is_night_category && isVisibleAfterHours) {
                      return <AfterHoursBanner category={category} />
                    }
                    if (category.is_promotion_category && !category.is_visible) {
                      return null
                    }
                    const find = cmsCategories?.find((each) => each.id == category.category_id)
                    let hasBannerAds: AdsProps
                    if (find) {
                      hasBannerAds = checkCmsInfo(find)
                    }
                    const badgeStyle = {}
                    if (hasBannerAds && hasBannerAds.mini_banner) {
                      badgeStyle['backgroundImage'] = 'url(' + hasBannerAds.mini_banner.url + ')'
                      return (
                        <Col
                          className="hp-cat-badge"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          xl={6}
                          key={`cb${category.category_id}`}
                        >
                          <VisibilitySensor
                            onChange={(isVisible) =>
                              sendSponsorInfoToGTM(
                                isVisible,
                                hasBannerAds,
                                `home-page-mini-banner-`,
                                adsSentToGTM,
                                sendDataToGTM
                              )
                            }
                          >
                            <div style={{ position: 'relative' }}>
                              <Link
                                href={`${
                                  hasBannerAds.mini_banner_link_ads
                                    ? hasBannerAds.mini_banner_link_ads
                                    : `/categoria/${category.slug}`
                                }`}
                              >
                                <a
                                  onClick={() => {
                                    sponsorClick(hasBannerAds, 'home-page-mini-banner-')
                                  }}
                                  aria-hidden="true"
                                  className="hp-cat-badge"
                                  style={{
                                    ...badgeStyle,
                                    paddingLeft: category.is_promotion_category ? 0 : '',
                                    paddingRight: category.is_promotion_category ? 0 : '',
                                  }}
                                >
                                  <span
                                    className="category-home-promotion"
                                    style={{
                                      fontSize: category.is_promotion_category
                                        ? width <= 330
                                          ? 10
                                          : width <= 400
                                          ? 12
                                          : width <= 450
                                          ? 14
                                          : width <= 500
                                          ? 16
                                          : width <= 800
                                          ? 18
                                          : width <= 991
                                          ? 22
                                          : width <= 1050
                                          ? 16
                                          : width <= 1100
                                          ? 18
                                          : width > 1150 && width < 1400
                                          ? 20
                                          : width > 1400
                                          ? 22
                                          : ''
                                        : '',
                                    }}
                                  >
                                    {category.is_promotion_category ? '' : category.name}
                                    <span className="icon">
                                      <RightOutlined />
                                    </span>
                                  </span>

                                  <SponsoredTag style={{ bottom: 6, right: 6 }}>Ad</SponsoredTag>
                                </a>
                              </Link>
                            </div>
                          </VisibilitySensor>
                        </Col>
                      )
                    }
                    if (category.banner !== null) {
                      badgeStyle['backgroundImage'] = 'url(' + category.banner + ')'
                    }

                    return (
                      <Col
                        className="hp-cat-badge"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        key={`cb${category.category_id}`}
                      >
                        <Link href={`/categoria/${category.slug}`}>
                          <a
                            className="hp-cat-badge"
                            style={{
                              ...badgeStyle,
                              paddingLeft: category.is_promotion_category ? 0 : '',
                              paddingRight: category.is_promotion_category ? 0 : '',
                            }}
                          >
                            <span
                              className="category-home-promotion"
                              style={{
                                fontSize: category.is_promotion_category
                                  ? width <= 330
                                    ? 10
                                    : width <= 400
                                    ? 12
                                    : width <= 450
                                    ? 14
                                    : width <= 500
                                    ? 16
                                    : width <= 800
                                    ? 18
                                    : width <= 991
                                    ? 22
                                    : width <= 1050
                                    ? 16
                                    : width <= 1100
                                    ? 18
                                    : width > 1150 && width < 1400
                                    ? 20
                                    : width > 1400
                                    ? 22
                                    : ''
                                  : '',
                              }}
                            >
                              {category.is_promotion_category ? '' : category.name}
                              <span className="icon">
                                <RightOutlined />
                              </span>
                            </span>
                          </a>
                        </Link>
                      </Col>
                    )
                  })}
              </Row>
            </div>

            {categories
              .sort((a, b) =>
                a.is_night_category == b.is_night_category ? 0 : a.is_night_category ? 1 : -1
              )
              .sort((a, b) =>
                a.is_promotion_category == b.is_promotion_category
                  ? 1
                  : a.is_promotion_category
                  ? 0
                  : -1
              )
              .slice(0, 4)
              .map((category) => {
                if (category.is_promotion_category) {
                  if (category.products.length <= 2) {
                    return null
                  }
                }
                const find = cmsCategories?.find((each) => each.id == category.category_id)
                let hasBannerAds: AdsProps
                if (find) {
                  hasBannerAds = checkCmsInfo(find)
                }
                return (
                  <div className="hp-section" key={category.category_id}>
                    <h2 className="hp-section-title">{category.name}</h2>
                    <Link href={`/categoria/${category.slug}`}>
                      <a className="hp-section-link">Ver todos</a>
                    </Link>

                    <Carousel {...settings} className="homepage-carousel-category-products">
                      {category.products &&
                        category.products.slice(0, 18).map((product, index) => {
                          return (
                            <HomeProductLayer
                              key={index}
                              category={category}
                              isInWishList={product.isInWishList}
                              product={product}
                              setShowLoginProductPage={setShowLoginProductPage}
                            />
                          )
                        })}
                    </Carousel>
                    {hasBannerAds &&
                      (hasBannerAds.banner_carousel_ads ||
                        hasBannerAds.banner_carousel_mobile_ads) && (
                        <VisibilitySensor
                          onChange={(isVisible) =>
                            sendSponsorInfoToGTM(
                              isVisible,
                              hasBannerAds,
                              `home-page-banner-carousel-`,
                              adsSentToGTM,
                              sendDataToGTM
                            )
                          }
                        >
                          <Link
                            href={`${
                              hasBannerAds.banner_carousel_link_ads
                                ? hasBannerAds.banner_carousel_link_ads
                                : '/'
                            }`}
                          >
                            <a
                              onClick={() =>
                                sponsorClick(hasBannerAds, 'home-page-banner-carousel-')
                              }
                              aria-hidden="true"
                            >
                              <div className="banner-carousel">
                                <div
                                  style={{
                                    backgroundImage: `url(${
                                      width <= 600 && hasBannerAds.banner_carousel_mobile_ads
                                        ? hasBannerAds.banner_carousel_mobile_ads?.url
                                        : hasBannerAds.banner_carousel_ads?.url
                                    })`,
                                    height: '100%',
                                    width: '100%',
                                    backgroundSize: 'cover',
                                  }}
                                />
                                <SponsoredTag
                                  style={{
                                    right: width < 1200 && width > 800 ? 33 : 8,
                                    bottom: 8,
                                  }}
                                >
                                  Ad
                                </SponsoredTag>
                              </div>
                            </a>
                          </Link>
                        </VisibilitySensor>
                      )}
                  </div>
                )
              })}
          </div>
        </>
      )}
    </PageLayout>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const { data: homePageInfo } = await cmsApi.get<HomePageCmsProps>('homepage-no-account')
  const { data: categoriesInfo } = await api.get<ServerSideProps>('home-page?typesense=true')
  let allCategories: ServerSideProps['categoryInfo'][0]['category'][] | null = null
  let cmscategories: CmsProps[] | null = null
  if (categoriesInfo) {
    if (categoriesInfo.categoryInfo) {
      allCategories = categoriesInfo.categoryInfo.map((each) => each.category)
    }
    if (categoriesInfo.cmsCategories) {
      cmscategories = categoriesInfo.cmsCategories
    }
  }
  return {
    props: {
      homePageInfo: homePageInfo,
      categories: allCategories,
      cmsCategories: cmscategories,
    }, // will be passed to the page component as props
    revalidate: 60,
  }
}
