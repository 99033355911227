import styled from 'styled-components'
import Title from 'antd/lib/typography/Title'
import { Image, Space } from 'antd'

export const StyledSpaceContent = styled(Space)`
  padding-left: 102px;
  width: 100%;

  @media (min-width: 1921px) {
    display: flex;
    max-width: 1560px;
    width: 100%;
    margin: 0 auto;
    padding-left: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    padding-left: 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 0px;
    flex-direction: column;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (min-width: 1200px) and (max-width: 1450px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  & > .ant-space-item:first-child {
    @media (max-width: 767px) {
      margin-right: 0 !important;
      margin-bottom: 32px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      margin-right: 0 !important;
      margin-bottom: 32px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      margin-right: 40px !important;
    }

    @media (min-width: 1200px) and (max-width: 1450px) {
      margin-right: 40px !important;
    }
  }

  & > .ant-space-item .ant-image {
    @media (max-width: 767px) {
      width: 100% !important;
      height: auto !important;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      width: 100% !important;
      height: auto !important;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      width: 550px !important;
      height: auto !important;
    }

    @media (min-width: 1200px) {
      width: auto !important;
      height: auto !important;
    }
  }
`
export const StyledTitle = styled(Title)`
  color: #f16749 !important;
  max-width: 565px;
  margin-bottom: 0px !important;
  @media (max-width: 767px) {
    padding: 0 24px;
    font-size: 24px !important;
    line-height: 32px !important;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 30px !important;
    line-height: 38px !important;
  }

  @media (min-width: 992px) and (max-width: 1400px) {
    font-size: 32px !important;
  }
`

export const StyledTitle4 = styled(Title)`
  color: #f16749 !important;
  max-width: 565px;
  margin-bottom: 0px !important;
  @media (max-width: 767px) {
    padding-left: 24px;
    font-size: 16px !important;
    line-height: 24px !important;
    margin-top: -16px;
  }
`

export const StyledImage = styled(Image)`
  @media (max-width: 767px) {
    width: 100% !important;
    height: auto !important;
    object-fit: cover;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 100% !important;
    height: auto !important;
    object-fit: cover;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 100% !important;
    height: auto !important;
  }

  @media (min-width: 1200px) {
    width: auto !important;
    height: auto !important;
  }

  @media (min-width: 1300px) and (max-width: 1450px) {
    max-height: 450px;
  }
`
