import { StyledContainer } from './styles'

const Container = ({ children }) => {
  return (
    <StyledContainer direction="vertical" size={0}>
      {children}
    </StyledContainer>
  )
}

export default Container
