import { Button, Carousel, Modal } from 'antd'
import Image from 'next/image'
import { DownloadAppSectionProps } from '@/Props/HomePage/DownloadAppSectionProps'
import { useRouter } from 'next/router'
import { ReviewsSectionProps } from '@/Props/HomePage/ReviewsSectionProps'
import React, { useEffect, useState } from 'react'
import { Container, ReviewCard, StyledModal } from './style'
import { Rate } from 'antd'

const ReviewsSection = ({ title, description, review }: ReviewsSectionProps) => {
  const router = useRouter()
  const [seeMore, setSeeMore] = useState(false)
  const [allDescription, setAllDescription] = useState('')
  const [count, setCount] = useState([])
  useEffect(() => {
    const newCount = []
    review.map((each, index) => {
      if (index <= 3) {
        newCount.push(index + 1)
      } else {
        newCount.push(...newCount)
      }
    })
    setCount(newCount.slice(0, review.length))
  }, [])
  const settings = {
    arrows: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    swipe: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
        },
      },
    ],
  }
  const changeSeeMore = (value, text?) => {
    if (value && text) {
      setSeeMore(true)
      setAllDescription(text)
    } else {
      setSeeMore(false)
      setAllDescription('')
    }
  }

  return (
    <Container>
      <p className="title">{title}</p>
      <p className="description">{description}</p>
      <Carousel className="carousel" {...settings}>
        {review.map((eachReview, index) => {
          return (
            <ReviewCard key={index}>
              <div className="wrapper">
                <Rate className="stars" disabled allowHalf value={eachReview.rate} />
                {eachReview.review_description.length > 300 ? (
                  <p className="review-description">
                    {eachReview.review_description.slice(0, 300) + '... '}
                    <button
                      onClick={() => changeSeeMore(true, eachReview.review_description)}
                      className="see-more"
                      style={{ textDecoration: 'underline' }}
                    >
                      ver mais
                    </button>
                  </p>
                ) : (
                  <p className="review-description">{eachReview.review_description}</p>
                )}
              </div>
              <img
                alt={eachReview.name}
                src={`/assets/images/swig-avatar-${count[index]}.svg`}
                className="avatar"
              />
              <p className="text">
                <strong>{eachReview.name}</strong>, {eachReview.city}
              </p>
            </ReviewCard>
          )
        })}
      </Carousel>
      <StyledModal
        visible={seeMore}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        onCancel={() => changeSeeMore(false)}
      >
        <ReviewCard style={{ height: '100%' }}>
          <div className="wrapper">
            <p className="review-description" style={{ marginTop: 0, fontSize: 16 }}>
              {allDescription}
            </p>
          </div>
        </ReviewCard>
      </StyledModal>
    </Container>
  )
}

export default ReviewsSection
