import styled from 'styled-components'
import { Button, Space, Row, Col } from 'antd'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'

export const ButtonCircleMedium = styled(Button)`
  min-width: 36px;
  height: 36px;
  background-color: #7b2e6f;
  border: unset;
`
export const TitleContainer = styled(Title)`
  color: #f16749 !important;
  margin-bottom: 0 !important;
  text-align: center !important;
  line-height: 45px !important;
`

export const TextContainer = styled(Text)`
  color: #393939 !important;
  margin-bottom: 0 !important;
  font-size: 14px;
  font-family: Lato;
  line-height: 24px;
  text-align: center !important;
  max-width: 399px;
  display: block;
`

export const TitleBlock = styled(Title)`
  color: #7b2e6f !important;
  margin-bottom: 0 !important;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 22px !important;
  }
`

export const StyledSpaceImg = styled(Space)`
  width: 100%;
  justify-content: center;
  height: 336px;
  margin-bottom: 22px;
  @media (max-width: 767px) {
    height: 246px;
  }
`
export const StyledSpaceTitle = styled(Space)`
  margin-bottom: 10px;
`
export const StyledSpaceBase = styled(Space)`
  padding-top: 68px;
  padding-bottom: 80px;
  @media (max-width: 767px) {
    padding-top: 42px;
    padding-bottom: 107px;
  }

  & > .ant-space-item:nth-child(1) {
    @media (max-width: 767px) {
      margin-bottom: 12px !important;
    }
  }

  & > .ant-space-item:nth-child(2) {
    @media (max-width: 767px) {
      margin-bottom: 32px !important;
    }
  }
`
export const StyledBtnBottom = styled(Button)`
  @media (max-width: 767px) {
    height: 40px;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 16px;
    line-height: 20px;
  }
`

export const StyledRow = styled(Row)`
  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 !important;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 !important;
  }
`
export const StyledCol = styled(Col)`
  @media (min-width: 560px) and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
