import { Col } from 'antd'
import Image from 'next/image'
import {
  StyledSpaceContent,
  StyledTitle,
  StyledSpaceIcons,
  StyledFacebookIcon,
  StyledInstagramIcon,
  StyledRow,
} from './styles'

import Link from 'next/link'
import { FollowUsProps } from '@/Props/HomePage/FollowUsProps'

const FollowUs = ({ title, link, follow_us_images }: FollowUsProps) => {
  return (
    <StyledSpaceContent direction="vertical" align="center" size={35}>
      <StyledTitle level={2}>{title}</StyledTitle>
      <StyledSpaceIcons direction="horizontal" size={35}>
        {link.map((linkIcon, index) => {
          return (
            <Link href={linkIcon.href} key={index}>
              <a target={linkIcon.target != null ? linkIcon.target : ''} rel="noreferrer">
                <Image
                  width={32}
                  height={32}
                  src={`${linkIcon.icon?.url}`}
                  alt={linkIcon.icon?.name}
                ></Image>
              </a>
            </Link>
          )
        })}
      </StyledSpaceIcons>
      <StyledRow justify="center" gutter={30}>
        {follow_us_images.map((image, index) => {
          return (
            <Col xs={12} sm={12} md={12} lg={6} xl={6} key={index}>
              <Image
                width={400}
                height={400}
                src={`${image?.image.url}`}
                alt={image?.image.name}
              ></Image>
            </Col>
          )
        })}
        {/*<Col xs={12} sm={12} md={12} lg={6} xl={6}>
          <Image
            width={400}
            height={400}
            src="/assets/images/follow-us/follow-us-1.jpg"
            alt="Imagem instagram"
          ></Image>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
          <Image
            width={400}
            height={400}
            src="/assets/images/follow-us/follow-us-2.jpg"
            alt="Imagem instagram"
          ></Image>
        </Col>
        <Col xs={0} sm={0} md={0} lg={6} xl={6}>
          <Image
            width={400}
            height={400}
            src="/assets/images/follow-us/follow-us-3.jpg"
            alt="Imagem instagram"
          ></Image>
        </Col>
        <Col xs={0} sm={0} md={0} lg={6} xl={6}>
          <Image
            width={400}
            height={400}
            src="/assets/images/follow-us/follow-us-4.jpg"
            alt="Imagem instagram"
          ></Image>
      </Col>*/}
      </StyledRow>
    </StyledSpaceContent>
  )
}

export default FollowUs
