import { Space } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import {
  StyledSpaceBanner,
  StyledTitleH1,
  StyledTitleH5,
  StyledSearchContainer,
  StyledBtn,
  SponsoredTag,
} from './styles'
import { MapsPlacesAutocomplete } from '@/components/shared/MapsPlacesAutocomplete'
import React, { useEffect, useState } from 'react'
import { useClient } from '@/contexts/ClientContext'
import Image from 'next/image'
import googleMapsAutocompleteParser from '@/utils/googleMapsAutocompleteParser'

import { HomePagePrincipalBannerProps } from '@/Props/HomePage/HomePagePrincipalBannerProps'
import { CurrentLocationButton } from '@/components/shared/CurrentLocationButton'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { isAfter, isBefore } from 'date-fns'
import { StrapiImageProps } from '@/Props/StrapiImageProps'
import { useWindowDimensions } from '@/utils/useWindowDimensions'
import VisibilitySensor from 'react-visibility-sensor'
import sendSponsorInfoToGTM from '@/utils/sendSponsorInfoToGTM'

export interface AdsHomePageBannerProps {
  image_ads: StrapiImageProps
  name_ads: string
  begin_date_ads: string
  end_date_ads: string
  image_ads_mobile: StrapiImageProps
}
const HomePagePrincipalBanner = ({
  content: { title, subtitle },
  image,
  multiple_content_ads_banner,
}: HomePagePrincipalBannerProps) => {
  const {
    changeClientAddress,
    changeClientCoordinates,
    changeClientCity,
    changeClientDistrict,
    changeClientCountry,
    changeClientPostalCode,
    changeClientAddressId,
    changeIsLoading,
    address,
    changeDeliveryZoneError,
    changeAddressError,
    changeClientFromIslands,
    district,
  } = useClient()

  const [customerAddress, setCustomerAddress] = useState('')
  const handleChange = (address) => {
    //console.log(address)
    setCustomerAddress(address)
  }
  const sendDataToGTM = useGTMDispatch()
  const { width } = useWindowDimensions()
  const [adToShow, setAdToShow] = useState<AdsHomePageBannerProps>()
  const [adsSentToGTM, setAdsSentToGTM] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const handleSelect = async (address: string, placeId: string) => {
    const response = await googleMapsAutocompleteParser({ placeId: placeId })
    if (response.postal_code && response.postal_code.length > 0) {
      // const splitedPostalCode = response.postal_code.split('-')
      // if (
      //   Number(splitedPostalCode.length == 2 ? splitedPostalCode[0] : response.postal_code) < 9000
      // ) {
      changeIsLoading(true)

      changeClientAddressId(placeId)
      if (response.city && response.street) {
        changeClientAddress(`${response.street}, ${response.city}`)
      } else if (response.street) {
        changeClientAddress(response.street)
      } else if (response.city) {
        changeClientAddress(response.city)
      } else {
        changeClientAddress(response.formatted_address.split(',')[0])
      }
      changeClientCity(response.city)
      changeClientDistrict(response.district)
      changeClientCountry(response.country)
      changeClientPostalCode(response.postal_code)
      changeClientCoordinates({
        latitude: response.lat.toString(),
        longitude: response.lng.toString(),
      })
      window.fbq('track', 'SubmitApplication', {
        content_name: 'selected_address',
      })
      changeIsLoading(false)
      changeDeliveryZoneError(false)
      // } else {
      //   changeDeliveryZoneError(true)
      // }
    } else {
      const responseMaps = await googleMapsAutocompleteParser({
        lat: response.lat.toString(),
        lng: response.lng.toString(),
      })
      // if (responseMaps.postal_code && responseMaps.postal_code.length > 0) {
      //   // const splitedPostalCode = responseMaps.postal_code.split('-')
      //   // if (
      //   //   Number(splitedPostalCode.length == 2 ? splitedPostalCode[0] : response.postal_code) < 9000
      //   // ) {
      //   changeIsLoading(true)

      //   changeClientAddressId(placeId)
      //   if (responseMaps.city && responseMaps.street) {
      //     changeClientAddress(`${responseMaps.street}, ${responseMaps.city}`)
      //   } else if (responseMaps.street) {
      //     changeClientAddress(responseMaps.street)
      //   } else if (responseMaps.city) {
      //     changeClientAddress(responseMaps.city)
      //   } else {
      //     changeClientAddress(response.formatted_address.split(',')[0])
      //   }
      //   changeClientCity(responseMaps.city)
      //   changeClientDistrict(responseMaps.district)
      //   changeClientCountry(responseMaps.country)
      //   changeClientPostalCode(responseMaps.postal_code)
      //   changeClientCoordinates({
      //     latitude: response.lat.toString(),
      //     longitude: response.lng.toString(),
      //   })
      //   window.fbq('track', 'SubmitApplication', {
      //     content_name: 'selected_address',
      //   })
      //   changeIsLoading(false)
      //   changeDeliveryZoneError(false)
      //   // } else {
      //   //   changeDeliveryZoneError(true)
      //   // }
      // }
      // if (
      //   responseMaps.district != 'Madeira' &&
      //   responseMaps.district != 'Açores' &&
      //   responseMaps.district != 'Azores'
      // )
      // else {
      changeIsLoading(true)

      changeClientAddressId(placeId)
      if (responseMaps.city && responseMaps.street) {
        changeClientAddress(`${responseMaps.street}, ${responseMaps.city}`)
      } else if (responseMaps.street) {
        changeClientAddress(responseMaps.street)
      } else if (responseMaps.city) {
        changeClientAddress(responseMaps.city)
      } else {
        changeClientAddress(response.formatted_address.split(',')[0])
      }
      changeClientCity(responseMaps.city)
      changeClientDistrict(responseMaps.district)
      changeClientCountry(responseMaps.country)
      changeClientPostalCode(responseMaps.postal_code)
      changeClientCoordinates({
        latitude: response.lat.toString(),
        longitude: response.lng.toString(),
      })
      window.fbq('track', 'SubmitApplication', {
        content_name: 'selected_address',
      })
      changeIsLoading(false)
      changeDeliveryZoneError(false)
    }
    // else {
    //   changeDeliveryZoneError(true)
    // }
    // }
  }

  useEffect(() => {
    if (district && district != '') {
      if (district == 'Madeira') {
        changeClientFromIslands('madeira')
      } else if (district == 'Açores' || district == 'Azores') {
        changeClientFromIslands('açores')
      } else {
        changeClientFromIslands('')
      }
    }
  }, [district])

  useEffect(() => {
    if (multiple_content_ads_banner) {
      setIsLoading(true)
      let adsFound = false
      multiple_content_ads_banner.map((eachAd) => {
        if (eachAd.name_ads && !adsFound) {
          if (eachAd.begin_date_ads && eachAd.end_date_ads) {
            if (
              isAfter(new Date(), new Date(eachAd.begin_date_ads)) &&
              isBefore(new Date(), new Date(eachAd.end_date_ads))
            ) {
              adsFound = true
              setAdToShow(eachAd)
              setIsLoading(false)
            }
          }
        }
        setIsLoading(false)
      })
    }
  }, [multiple_content_ads_banner])

  return (
    <VisibilitySensor
      partialVisibility={true}
      delayedCall={true}
      onChange={(isVisible) => {
        sendSponsorInfoToGTM(isVisible, adToShow, `home-page-banner-`, adsSentToGTM, sendDataToGTM)
      }}
    >
      <StyledSpaceBanner
        style={{ padding: width <= 767 && !adToShow ? '0 24px' : '' }}
        className="home-hero"
      >
        <Image
          layout="fill"
          src={`${
            adToShow
              ? width <= 600 && adToShow.image_ads_mobile
                ? adToShow.image_ads_mobile.url
                : adToShow.image_ads.url
              : image.url
          }`}
          objectFit="cover"
          priority={true}
          loading="eager"
          alt="Banner principal"
        ></Image>
        {adToShow && <SponsoredTag>Ad</SponsoredTag>}
        <Space
          className="content"
          style={{ marginLeft: adToShow ? -8 : 0 }}
          direction="vertical"
          size={32}
        >
          <StyledTitleH1>{title}</StyledTitleH1>
          <StyledTitleH5 level={5}>{subtitle}</StyledTitleH5>
          <StyledSearchContainer className="search-container" direction="horizontal" size={0}>
            <MapsPlacesAutocomplete
              key={0}
              placeholder="A tua morada ou código-postal"
              address={customerAddress}
              handleChange={handleChange}
              handleSelect={handleSelect}
              isAddressDeleted={false}
            />
            <StyledBtn type="primary" size="large" icon={<ArrowRightOutlined />}></StyledBtn>
          </StyledSearchContainer>
          <CurrentLocationButton />
        </Space>
      </StyledSpaceBanner>
    </VisibilitySensor>
  )
}

export default HomePagePrincipalBanner
