import { Form, Input, Button, Divider } from 'antd'
import Container from '@/components/shared/Container'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import { ArrowRightOutlined } from '@ant-design/icons'
import { AutoDestroyModal } from '@/components/shared/AutoDestroyModal'
import validateMessages from '@/utils/validateMessages'
import Link from 'next/link'
import { NewsLetterContent } from './styles'
import api from '@/services/api'
import { useState } from 'react'
import { NewsletterSectionProps } from '@/Props/HomePage/NewsletterSectionProps'

interface NewsletterFormProps {
  name: string
  email: string
}

const NewsletterSection = ({
  title,
  button_text,
  description_text,
  link,
}: NewsletterSectionProps) => {
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false)
  const [form] = Form.useForm()
  const onFinish = async ({ name, email }: NewsletterFormProps) => {
    setIsSendButtonDisabled(true)
    const { data } = await api.post(`/newsletter?website=true`, {
      name,
      email,
    })

    window.fbq('track', 'Subscribe', {
      content_name: 'website',
    })

    if (data.code == 200) {
      AutoDestroyModal({
        title: 'Estás quase, SW!G. ',
        content: 'Confirma o teu email para não perderes o que temos para ti!',
        type: 'success',
      })
      form.resetFields()
    } else {
      AutoDestroyModal({
        type: 'error',
        title: 'Ocorreu um erro ao enviar o seu pedido.',
        content: 'Se o problema persistir contacte o suporte.',
      })
    }
    setIsSendButtonDisabled(false)
  }

  return (
    <NewsLetterContent className="newsletter-section">
      <Container>
        <div className="newsletter-content">
          <Title level={2}>{title}</Title>
          <Form
            form={form}
            validateMessages={validateMessages}
            scrollToFirstError
            requiredMark={false}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item label="Nome" name="name" rules={[{ required: true }]}>
              <Input placeholder="Nome" type="text" />
            </Form.Item>
            <Form.Item
              label="Endereço de email"
              name="email"
              rules={[{ required: true }, { type: 'email', message: 'O email não é válido' }]}
            >
              <Input placeholder="Endereço de email" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isSendButtonDisabled}
                loading={isSendButtonDisabled}
              >
                {button_text} <ArrowRightOutlined />
              </Button>
            </Form.Item>
          </Form>
          <Text className="terms-and-conditions-wrapper">
            {description_text}{' '}
            <Link href={link.href}>
              <a target={link.target != null ? link.target : ''}>{link.label}</a>
            </Link>
          </Text>
        </div>
        <Divider />
      </Container>
    </NewsLetterContent>
  )
}

export default NewsletterSection
