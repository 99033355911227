import { Button } from 'antd'
import Link from 'next/link'
import { AfterHoursBanner } from './style'

const afterHoursBanner = ({ category }) => {
  return (
    <AfterHoursBanner style={{ backgroundImage: `url(${category.banner})` }}>
      <Link href={`/categoria/${category.slug}`}>
        <a className="banner-text-wrapper">
          <div className="text-wrapper">
            <p className="banner-title">{category.name}</p>
            <p className="banner-subtitle">Para que não te falte bebida durante a noite</p>
          </div>
          <img
            className="moon-after-hours"
            src="/assets/images/moon-after-hours.svg"
            alt="after-hours"
          />
          <Button
            type="primary"
            className="button-after-hours"
            style={{ fontSize: 16 }}
            // onClick={() => {
            //   router.push('/')
            // }}
          >
            Ver produtos
          </Button>
        </a>
      </Link>
    </AfterHoursBanner>
  )
}
export default afterHoursBanner
