import styled from 'styled-components'
import { Space, Image } from 'antd'
import Text from 'antd/lib/typography/Text'
import Title from 'antd/lib/typography/Title'

export const StyledSpaceContent = styled(Space)`
  margin-top: -102px;
  justify-content: flex-end;
  width: 100%;
  padding-right: 102px;

  @media (min-width: 1921px) {
    display: flex;
    max-width: 1560px;
    width: 100%;
    margin: 0 auto;
    margin-top: -102px;
    padding-right: 0;
  }

  @media (max-width: 991px) {
    margin-top: 0;
    flex-direction: column-reverse;
    padding-right: 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 60px;
    padding: 0 30px;
  }

  @media (min-width: 1200px) and (max-width: 1450px) {
    padding: 0 30px;
    align-items: center;
  }

  @media (min-width: 1300px) and (max-width: 1450px) {
    margin-top: -60px;
  }

  & > .ant-space-item:last-child .ant-image {
    @media (max-width: 991px) {
      width: auto !important;
      height: auto !important;
      margin-bottom: 24px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      width: 550px !important;
      height: auto !important;
    }

    @media (min-width: 1200px) and (max-width: 1450px) {
      width: auto !important;
      height: auto !important;
    }
  }

  & > .ant-space-item:first-child .ant-image {
    @media (max-width: 991px) {
      width: auto !important;
      height: auto !important;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      width: auto !important;
      height: auto !important;
    }

    @media (min-width: 1200px) {
      width: auto !important;
      height: auto !important;
    }
  }

  & > .ant-space-item:first-child .ant-image img {
    @media (max-width: 767px) {
      object-fit: contain;
    }
  }
`
export const StyledSpaceText = styled(Space)`
  margin-right: 149px;
  max-width: 447px;
  text-align: right;

  @media (min-width: 992px) and (max-width: 1450px) {
    margin-right: 40px;
    max-width: 100vw;
  }

  @media (max-width: 991px) {
    margin-right: 0px;
    max-width: 80vw;
  }

  @media (max-width: 767px) {
    margin-top: 10px;
    margin-right: 0;
    padding: 0 24px;
    max-width: 100vw;
    /* max-width: unset; */
  }
`

export const StyledTitle = styled(Title)`
  color: #f16749 !important;
  width: 80%;
  margin-right: 0px;
  margin-left: auto;
  line-height: 45px !important;
  @media (max-width: 767px) {
    font-size: 30px !important;
    line-height: 38px !important;
  }
`
export const StyledText = styled(Text)`
  color: #f16749 !important;
  font-family: Lato;
  line-height: 24px !important;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`

export const StyledImage = styled(Image)`
  padding-right: 102px;
  @media (max-width: 991px) {
    width: 100% !important;
    padding: 0;
    height: auto !important;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 100% !important;
    padding-right: 0;
    height: auto !important;
  }

  @media (min-width: 1200px) and (max-width: 1450px) {
    height: auto !important;
    padding: 0;
  }

  @media (min-width: 1300px) and (max-width: 1450px) {
    height: auto !important;
    padding: 0;
    max-height: 450px;
    width: auto !important;
  }
`
