import { Button } from 'antd'
import Image from 'next/image'
import { StyledSpaceContent, StyledSpaceText, StyledTitle, StyledText } from './styles'
import { DownloadAppSectionProps } from '@/Props/HomePage/DownloadAppSectionProps'
import { useRouter } from 'next/router'
import { StrapiImageProps } from '@/Props/StrapiImageProps'
import React, { useEffect, useState } from 'react'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { isAfter, isBefore } from 'date-fns'
import { SponsoredTag } from '../HomePagePrincipalBanner/styles'
import VisibilitySensor from 'react-visibility-sensor'
import sendSponsorInfoToGTM from '@/utils/sendSponsorInfoToGTM'

export interface AdsDownloadAppProps {
  image_ads: StrapiImageProps
  title_ads: string
  text_ads: string
  label_ads: string
  target_ads: string
  href_ads: string
  begin_date_ads?: string
  end_date_ads?: string
}

const DownloadAppSection = ({
  content: { title, text, label, target, href },
  image,
  multiple_content_ads_app_hero,
}: DownloadAppSectionProps) => {
  const router = useRouter()
  const [adToShow, setAdToShow] = useState<AdsDownloadAppProps>()
  const sendDataToGTM = useGTMDispatch()
  const [adsSentToGTM, setAdsSentToGTM] = useState<string[]>([])

  useEffect(() => {
    if (multiple_content_ads_app_hero) {
      let adsFound = false
      multiple_content_ads_app_hero.map((eachAd) => {
        if (eachAd.image_ads && eachAd.title_ads && !adsFound) {
          if (eachAd.begin_date_ads && eachAd.end_date_ads) {
            if (
              isAfter(new Date(), new Date(eachAd.begin_date_ads)) &&
              isBefore(new Date(), new Date(eachAd.end_date_ads))
            ) {
              adsFound = true
              setAdToShow(eachAd)
            }
          }
        }
      })
    }
  }, [multiple_content_ads_app_hero])

  const promotionClick = () => {
    if (adToShow) {
      const adsName = adToShow.title_ads.toLocaleUpperCase().split('')
      adsName.map((char, index) => {
        const checkIfHasWhiteSpace = (s) => {
          return /\s/g.test(s)
        }
        const hasWhiteSpace = checkIfHasWhiteSpace(char)
        if (hasWhiteSpace) {
          adsName[index] = '-'
        }
      })
      sendDataToGTM({ ecommerce: null })
      sendDataToGTM({
        event: 'promotionClick',
        ecommerce: {
          promoView: {
            promotions: [
              {
                id: adsName.join(''),
                name: adToShow.title_ads,
                creative: `home-page-app-hero-section-${adsName.join('').toLocaleLowerCase()}`,
                position: `home-page-app-hero-section-${adsName.join('').toLocaleLowerCase()}`,
              },
            ],
          },
        },
      })
      if (adToShow.target_ads != 'nova_pagina') {
        router.push(adToShow.href_ads)
      } else {
        window.open(`${adToShow.href_ads}`, '_blank')
      }
    }
  }
  return (
    <VisibilitySensor
      onChange={(isVisible) =>
        sendSponsorInfoToGTM(
          isVisible,
          adToShow,
          `home-page-app-hero-section-`,
          adsSentToGTM,
          sendDataToGTM
        )
      }
    >
      <StyledSpaceContent direction="horizontal" align="center" size={0}>
        <StyledSpaceText direction="vertical" size={16}>
          <StyledTitle level={2}>{adToShow ? adToShow.title_ads : title}</StyledTitle>
          <StyledText>{adToShow ? adToShow.text_ads : text}</StyledText>
          <div style={{ marginTop: '16px' }} className="button-see-blog">
            <Button
              type="primary"
              onClick={() => {
                if (adToShow) {
                  promotionClick()
                } else if (target != 'nova_pagina') {
                  router.push(`${href}`)
                } else {
                  window.open(`${href}`, '_blank')
                }
              }}
            >
              {adToShow ? adToShow.label_ads : label}
            </Button>
          </div>
        </StyledSpaceText>
        <div style={{ position: 'relative' }}>
          <Image
            width={843}
            height={626}
            src={`${adToShow ? adToShow.image_ads.url : image.url}`}
            alt="Na SW!G também somos bloggers"
          ></Image>
          {adToShow && <SponsoredTag style={{ bottom: 15, right: 10 }}>Ad</SponsoredTag>}
        </div>
      </StyledSpaceContent>
    </VisibilitySensor>
  )
}

export default DownloadAppSection
