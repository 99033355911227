import styled from 'styled-components'

export const HomePageLoadingSkeleton = styled.div`
  .products-loading-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 30px;
  }
  .categories-loading-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    margin-top: 30px;
    padding: 0px 15px;
    height: 109px;
    width: 100%;
    margin-bottom: 20px;
  }
  .name-link-wrapper {
    width: fit-content;
    margin: auto;
    height: 106px;
  }
  .link-mobile {
    display: none;
  }
  .layer-product-loading-wrapper {
    padding: 64px 30px;
  }
  @media (max-width: 1365px) {
    .products-loading-wrapper {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    .products-loading-wrapper svg:nth-child(6) {
      display: none;
    }
  }
  @media (max-width: 1200px) {
    .products-loading-wrapper {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .products-loading-wrapper svg:nth-child(5) {
      display: none;
    }
  }
  @media (max-width: 1000px) {
    .categories-loading-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      margin-top: 30px;
      padding: 0px 15px;
      height: 182px;
    }
  }
  @media (max-width: 800px) {
    .link {
      display: none;
    }
    .name-link-wrapper {
      height: 64px;
    }
    .link-mobile {
      display: block;
      height: 42px;
      margin-top: 32px;
    }
    .layer-product-loading-wrapper {
      padding: 70px 30px 32px;
    }
  }
  @media (max-width: 767px) {
    .categories-loading-wrapper {
      height: 116px;
    }
  }
  @media (max-width: 900px) {
    .products-loading-wrapper {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .products-loading-wrapper svg:nth-child(4) {
      display: none;
    }
  }
  @media (max-width: 600px) {
    .products-loading-wrapper {
      grid-template-columns: 1fr 1fr;
    }
    .layer-product-loading-wrapper {
      padding: 70px 15px 32px;
    }
    .layer-product-loading-wrapper:nth-child(4) {
      display: none;
    }
    .layer-product-loading-wrapper:nth-child(3) {
      display: none;
    }
    .products-loading-wrapper svg:nth-child(3) {
      display: none;
    }
  }
`
