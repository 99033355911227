import ContentLoader from 'react-content-loader'
import { HomePageLoadingSkeleton } from './style'

const TopLoading = ({ isVisibleAfterHours }) => {
  return (
    <>
      <div className="categories-loading-wrapper">
        <ContentLoader
          speed={1}
          width="100%"
          height="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
        </ContentLoader>
        <ContentLoader
          speed={1}
          width="100%"
          height="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
        </ContentLoader>
        <ContentLoader
          speed={1}
          width="100%"
          height="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
        </ContentLoader>
        <ContentLoader
          speed={1}
          width="100%"
          height="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
        </ContentLoader>
      </div>
      {isVisibleAfterHours && (
        <div style={{ width: '100%', margin: 'auto', padding: '0 15px' }}>
          <ContentLoader
            speed={1}
            width="100%"
            height="100%"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="174" />
          </ContentLoader>
        </div>
      )}
    </>
  )
}
const LayerProductLoading = ({ borderBottom }) => {
  return (
    <div
      className="layer-product-loading-wrapper"
      style={{
        borderBottom: borderBottom,
      }}
    >
      <div className="name-link-wrapper">
        <ContentLoader
          speed={1}
          width="150"
          height="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="150" height="38" />
          <rect className="link" x="35" y="54" rx="0" ry="0" width="80" height="20" />
        </ContentLoader>
      </div>
      <div className="products-loading-wrapper">
        <ContentLoader
          speed={1}
          width="100%"
          height={374}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="374" />
        </ContentLoader>
        <ContentLoader
          speed={1}
          width="100%"
          height={374}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="374" />
        </ContentLoader>
        <ContentLoader
          speed={1}
          width="100%"
          height={374}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="374" />
        </ContentLoader>
        <ContentLoader
          speed={1}
          width="100%"
          height={374}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="374" />
        </ContentLoader>
        <ContentLoader
          speed={1}
          width="100%"
          height={374}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="374" />
        </ContentLoader>
        <ContentLoader
          speed={1}
          width="100%"
          height={374}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="374" />
        </ContentLoader>
      </div>
      <div className="link-mobile">
        <ContentLoader
          speed={1}
          width="100%"
          height="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
        </ContentLoader>
      </div>
    </div>
  )
}
const HomePageLoading = ({ isVisibleAfterHours }) => {
  return (
    <HomePageLoadingSkeleton>
      <TopLoading isVisibleAfterHours={isVisibleAfterHours} />
      <LayerProductLoading borderBottom={'1px solid #C4C4C4'} />
      <LayerProductLoading borderBottom={'1px solid #C4C4C4'} />
      <LayerProductLoading borderBottom={'1px solid #C4C4C4'} />
      <LayerProductLoading borderBottom={'0px'} />
    </HomePageLoadingSkeleton>
  )
}
export default HomePageLoading
