import Container from '@/components/shared/Container'
import { useClient } from '@/contexts/ClientContext'
import { HowItWorksProps } from '@/Props/HomePage/HowItWorksProps'
import { Space } from 'antd'
import Image from 'next/image'
import { useRouter } from 'next/router'
import {
  ButtonCircleMedium,
  TitleContainer,
  TitleBlock,
  TextContainer,
  StyledSpaceImg,
  StyledSpaceTitle,
  StyledSpaceBase,
  StyledBtnBottom,
  StyledRow,
  StyledCol,
} from './styles'
import AfterHoursBanner from '@/components/AfterHoursBanner'

const HowItWorks = ({ title, button, description, nightCategory }: HowItWorksProps) => {
  const router = useRouter()
  const { isVisibleAfterHours } = useClient()
  return (
    <Container>
      {nightCategory && isVisibleAfterHours && (
        <AfterHoursBanner key={1} category={nightCategory} />
      )}
      <StyledSpaceBase className="how-it-works" direction="vertical" align="center" size={60}>
        <TitleContainer level={2}>{title}</TitleContainer>

        <StyledRow gutter={[{ md: 30 }, { xs: 30 }]} justify="center">
          {description.map((content, index) => {
            return (
              <StyledCol
                className={`step ${index++}`}
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                key={content.id}
                style={{ justifyContent: 'center', textAlign: 'center' }}
              >
                <StyledSpaceImg className="img">
                  <Image
                    width={215}
                    height={162}
                    src={`${content.image.url}`}
                    alt="Diz-nos onde estás"
                  ></Image>
                </StyledSpaceImg>
                <StyledSpaceTitle className="title" direction="horizontal">
                  <ButtonCircleMedium type="primary" shape="circle">
                    {index++}
                  </ButtonCircleMedium>
                  <TitleBlock level={4}>{content.title}</TitleBlock>
                </StyledSpaceTitle>
                <Space className="text">
                  <TextContainer>{content.text}</TextContainer>
                </Space>
              </StyledCol>
            )
          })}
        </StyledRow>
        <StyledBtnBottom
          type="primary"
          size="large"
          onClick={() => {
            if (button.link.target != 'nova_pagina') {
              router.push(`${button.link.href}`)
            } else {
              window.open(`${button.link.href}`, '_blank')
            }
          }}
        >
          {button.link.label}
        </StyledBtnBottom>
      </StyledSpaceBase>
    </Container>
  )
}

export default HowItWorks
