import styled from 'styled-components'

export const StyledCatalogPromotionPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .product-list-item-promotion-price {
    font-family: Sora;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #43043b;
    margin-bottom: 0;
  }

  .product-list-item-old-price {
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    color: #6b6b6b;
    text-decoration: line-through;
    margin-left: 10px;
  }
`
