import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
const antIcon = <LoadingOutlined style={{ fontSize: 24, marginTop: '-30px' }} spin />

interface Props {
  paddingTop?: number
  paddingBottom?: number
  width?: string
}
const LoadingCenter = ({ paddingTop = 200, paddingBottom = 500, width = '100%' }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: width,
        flexDirection: 'row',
        height: '100%',
        alignItems: 'center',
        paddingTop: paddingTop,
        paddingBottom: paddingBottom,
      }}
    >
      <p style={{ margin: 0, marginRight: '15px', fontSize: 16 }}>A carregar</p>
      <Spin indicator={antIcon} />
    </div>
  )
}
export default LoadingCenter
